import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import FloatingSection from "../../components/floatingSection";

function ContactDetails() {
  const data = useStaticQuery(graphql`
    query ContactDetailsQuery {
      contact: markdownRemark(frontmatter: {templateKey: {eq: "contact"}}) {
        frontmatter {
          miscContactDetails {
            correspondenceAddress
            gmapsEmbedURL
            officeTimings
          }
        }
      }
      common: markdownRemark(frontmatter: {dataId: {eq: "common"}}) {
        frontmatter {
          contactDetails {
            phone
            phoneDisplay
            mobile
            email
            website
          }
          socialMedia {
            link
            title
          }
        }
      }
    }
  `)
  const {correspondenceAddress, gmapsEmbedURL, officeTimings} = data.contact.frontmatter.miscContactDetails;
  const {
    contactDetails: {
      phone,
      phoneDisplay,
      mobile,
      email,
      website,
    },
    socialMedia
  } = data.common.frontmatter;

  return (
    <div className="relative mt-40">
      <iframe src={gmapsEmbedURL} className="h-[80vh] md:h-[180vh] xl:h-[120vh] w-[100%]" allowFullScreen="" loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"/>
      <div className="md:absolute md:w-1/2 top-0 right-0">
        <FloatingSection>
          <div className="bg-red-900 w-2/3 mx-auto -translate-y-36 rounded-md p-10 text-white text-md break-words">

            <p className="mb-2">
              <b>All official correspondence may please be addressed as under:</b>
            </p>
            {correspondenceAddress.map((line, index) => (
              <p className="mb-2" key={index}>{line}</p>
            ))}
            <p className="mb-5">Email – <a href={`mailto:${email}`} className="hover:text-yellow-400">{email}</a></p>

            <p><b>Telephone No</b>:</p>
            <a href={`tel:${phone}`} className="block hover:text-yellow-400 mb-5">{phoneDisplay}</a>
            <p><b>Mobile</b>:</p>
            <a href={`tel:${mobile}`} className="block hover:text-yellow-400 mb-5">{mobile}</a>
            <p><b>Website</b>:</p>
            <a href={website} className="block hover:text-yellow-400 mb-5">{website}</a>

            {socialMedia.map(({title, link}, index) => (
              <React.Fragment key={index}>
                <p><b>{title}</b>:</p>
                <a href={link} className="block hover:text-yellow-400 mb-5">{link}</a>
              </React.Fragment>
            ))}
            <p className="text-justify">{officeTimings}</p>
          </div>
        </FloatingSection>
      </div>
    </div>
  )
}

export default ContactDetails;
