import React from "react";
import {graphql, useStaticQuery} from "gatsby";

function ContactList() {
  const data = useStaticQuery(graphql`
    query ContactListQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "contact"}}) {
        frontmatter {
          contactList {
            position
            name
            phone
            email
          }
        }
      }
    }
  `)
  const {contactList} = data.markdownRemark.frontmatter;

  return (
    <div className="flex flex-row flex-wrap px-20 pt-20">
      {contactList.map(({name, position, phone, email}, index) => (
        <div key={index} className="w-full md:w-1/2 xl:w-1/3 p-10">
          {!!position && <p className="text-lg font-bold text-yellow-500 mb-5">{position}</p>}
          {!!name && <p className="text-xl mb-2">{name}</p>}
          {!!phone && <p className="text-md font-bold mb-2">P: {phone}</p>}
          {!!email && <a href={`mailto:${email}`} className="text-md font-bold">E: {email}</a>}
        </div>
      ))}
    </div>
  )
}

export default ContactList;
